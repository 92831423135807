import React  from 'react'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { ResponseOne } from './response1'
import { ResponseTwo } from './response2'

type Props = {
  testId: string
  response?: any
}
const useStyles = makeStyles({
  response: {
    background: '#FAFAFA',
  }
})


export const FormResponse: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  let content = props.testId === 'IFx9tQ' ? <ResponseOne response={props.response} /> : <ResponseTwo response={props.response} />
  return (
      <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={ classes.response }>
          <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography color={'primary'}>{expanded ? 'Skrýt' : 'Zobrazit'} odpovědi</Typography>
          </ExpansionPanelSummary>
          {content}
      </ExpansionPanel>
  )
}
