import React from 'react'
import styled from 'styled-components'

const StyledLoader = styled.div`
  text-align: center;
`

export const Loader: React.FC = () => (
  <StyledLoader>
    <p>Loading ...</p>
  </ StyledLoader>
)
