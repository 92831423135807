import React from 'react'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

type rsp = {
  title: string,
  main: string[]
  strengths: [ {
    title: string,
    text: string
  } ]
  weaknesses: [ {
    title: string,
    text: string
  } ]
}

type Props = {
  response: rsp
}

export const ResponseTwo: React.FC<Props> = (props) => {
  const strengths = props.response.strengths.map(s => (<Grid item xs={ 12 }>
    <Typography>{ s.title }</Typography>
    <Typography>{ s.text }</Typography></Grid>))
  const weaknesses = props.response.weaknesses.map(w => (<Grid item xs={ 12 }>
    <Typography>{ w.title }</Typography>
    <Typography>{ w.text }</Typography></Grid>))
  return (

    <ExpansionPanelDetails>
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 }>
          <Typography variant="h6" component="h2">{ props.response.title }</Typography>
        </Grid>
        <Grid item xs={ 12 }>
          <Typography>Extroverze popisuje schopnost člověka být cítít se dobře když se vyskytuje ve skupině lidí vs když je sám.</Typography>
        </Grid>
        { strengths }
        { weaknesses }
      </Grid>
    </ExpansionPanelDetails>
  )
}
