import React  from 'react'
import { Card, Link, CardActions, makeStyles } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { FormResponse } from './response'

type Props = {
  id: 'IFx9tQ' | 'Gr65UE' | 'ypGdvk' | 'mPodZM' | 'mnhbP1'
  userId: string
  responses?: any
}

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    maxWidth: 600,
    background: '#FAFAFA',
    marginBottom: 20,
    textAlign: 'left'
  }
})

const titles = {
  'IFx9tQ': 'DEV test',
  'mnhbP1': 'Warm-up',
  'mPodZM': 'Test 1',
  'ypGdvk': 'Test 2',
  'Gr65UE': 'Test 3'
}
const texts = {
  'IFx9tQ': 'test',
  'mnhbP1': 'Zahřívací test než se pustíš do ostrých, tak šup bude to sranda',
  'mPodZM': 'První ostrý test, kde se dozvíš své charakterové něco...',
  'ypGdvk': 'Zjistíš svoje silné a slabé stránky',
  'Gr65UE': 'Poodkryj další vlastnosti, které tě charakterizují'
}

export const FormCard: React.FC<Props> = (props) => {
  const classes = useStyles()
  const link = `/form/${props.id}?shapeid=${props.userId}`

  return (
    <Card style={ { width: '90%' } } className={ classes.card }>
      <CardContent>
        <Typography variant="h5" component="h2">{ titles[props.id] }</Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {texts[props.id]}
        </Typography>
      </CardContent>
      <CardActions>
        { props.responses ?
          (<FormResponse testId={props.id} response={props.responses}/>)
          : (
            <Link component={RouterLink} to={link}>
              Spustit test
            </Link>
          ) }
      </CardActions>
    </Card>

  )
}
