import React from 'react'
import { ExpansionPanelDetails, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

type Rsp = {
  name: string,
  desc: string,
  title: string,
  nature: string[]
}
type rsps = {
  E: Rsp,
  A: Rsp,
  C: Rsp,
  N: Rsp,
  O: Rsp
}
type Props = {
  response: rsps
}

type ItemProps = {
  type: 'E' | 'A' | 'C' | 'N' | 'O',
  properties: Rsp
}

const Item: React.FC<ItemProps> = (props) => {
  return (
    <Grid item xs={ 12 }>
      <Typography variant="h6" component="h2">{ props.properties.name }</Typography>
      <Typography color="textSecondary" component="p">{ props.properties.desc }</Typography>
      <Typography variant="subtitle1" component="p">{ props.properties.title }</Typography>
      <Typography component="p">{ props.properties.nature.join(' • ') }</Typography>
    </Grid>
  )
}

export const ResponseOne: React.FC<Props> = (props) => {
  return (

    <ExpansionPanelDetails>
      <Grid container spacing={ 3 }>
        <Item type={'E'} properties={props.response.E}/>
        <Item type={'A'} properties={props.response.A}/>
        <Item type={'C'} properties={props.response.C}/>
        <Item type={'N'} properties={props.response.N}/>
        <Item type={'O'} properties={props.response.O}/>

      </Grid>
    </ExpansionPanelDetails>
  )
}
