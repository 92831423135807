import React  from 'react'
import styled from 'styled-components'
import { Forms } from './components/forms'
import { Info } from './components/info'
import { Register } from './components/register'
import { Box, Container } from '@material-ui/core'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { Form } from './components/form'

const StyledApp = styled.div`
  text-align: center;
`

const App: React.FC = () => {
  return (
    <Router>
      <StyledApp>
        <Container maxWidth="md">
          <Switch>
            <Route path="/forms">
              <Forms/>
            </Route>
            <Route path="/form/:formId" children={<Form/>} />
            <Route path="/register-kp">
              <Register />
            </Route>
            <Route path="/">
              <Info/>
            </Route>
          </Switch>
        </Container>
      </StyledApp>
    </Router>
  )
}

export default App
