import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
// @ts-ignore
import Mailchimp from 'react-mailchimp-form'

const useStyles = makeStyles({
  hidden: {
    position: 'absolute',
    left: -5000
  }
})

export const Register: React.FC = () => {
  const classes = useStyles()
  return (
    <div>
      <Typography variant="h3">Register</Typography>
      <Mailchimp
        action='https://cocuma.us11.list-manage.com/subscribe/post?u=d4472cf6ab0e6e4619fcc1387&amp;id=4531f72bb3'
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
        ]}
      />
    </ div>
  )
}
