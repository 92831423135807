import React from 'react'
import { Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

export const Info: React.FC = () => (
  <div>
    <Typography variant="h3">HEader</Typography>
    <Button variant="contained" color="primary" onClick={()=> window.open("https://goout.net/cs/praha/", "_blank")}>Goout.cz</Button>
    <p>Please buy your ticket at goout.cz</p>
  </ div>
)
