import { Stitch, FunctionCredential, StitchUser } from 'mongodb-stitch-browser-sdk'

let user: StitchUser
export const login = async (shapeId?: string): Promise<StitchUser> => {
  if (!shapeId) {
    throw new Error('Unauthorized')
  }
console.log('shapeeeeeee', shapeId)
  if (!user) {
    Stitch.initializeDefaultAppClient('blogtutorial-kihdf')
    const credentials = new FunctionCredential({ shapeid: shapeId })
    user = await Stitch.defaultAppClient.auth.loginWithCredential(credentials)
  }

  return user
}
