import React, { useEffect } from 'react'
// @ts-ignore
import * as typeform from '@typeform/embed'
import Typography from '@material-ui/core/Typography'
import { useHistory, useLocation, useParams } from 'react-router'
import { Box } from '@material-ui/core'


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const Form: React.FC = () => {
  let { formId } = useParams()
  const query = useQuery()
  let history = useHistory()

  console.log(formId)
  useEffect(() => {
    const el = document.getElementById("typeform")
    typeform.makeWidget(el, `https://kariernipout.typeform.com/to/${formId}?shapeid=${query.get('shapeid')}`, {
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
      onSubmit: function () {
        history.push(`/forms?shapeid=${query.get('shapeid')}`)
      }
    })
  }, [])

  return (
    <Box id={"typeform"} minHeight={300} height="300px">
      <Typography variant="h3">HEader</Typography>
    </Box>
  );
}
