import React, { useEffect, useState } from 'react'
import { FormCard } from './formCard'
import Typography from '@material-ui/core/Typography'
import { login } from '../utils/login'
import { Stitch } from 'mongodb-stitch-browser-sdk'
import { Loader } from './loader'
import { useLocation } from "react-router-dom";

type Responses = {
  'IFx9tQ'?: string
  'Gr65UE'?: any
  'ypGdvk'?: any
  'mPodZM'?: any
  'mnhbP1'?: any
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const Forms: React.FC = () => {

  const [ responses, setResponses ] = useState<Responses>({})
  const [ loading, setLoading ] = useState<Boolean>(true)
  const [ error, setError ] = useState<Error>()
  const query = useQuery()
  const shapeId = query.get('shapeid')

  useEffect(() => {
    login( shapeId || undefined)
      .then(authUser =>  {
        return Stitch.defaultAppClient.callFunction('getFormResult', [ { id: shapeId } ]).then(setResponses).then(() => {
          setLoading(false)
        })
      })
      .catch(err => {
        setError(new Error('Unauthenticated'))
      })
  }, [])

  if (error) {
    return (
      <div>error</div>
    )
  }

  if (loading || !shapeId) {
    return (
      <Loader></Loader>
    )
  }

  return (
    <div>
      <Typography variant="h3">HEader</Typography>
      <div>user with id {shapeId}</div>
      {!responses['mnhbP1'] && (<FormCard id={'mnhbP1'} userId={shapeId} responses={responses['mnhbP1']}/>)}
      <FormCard id={'IFx9tQ'} userId={shapeId} responses={responses['IFx9tQ']}/>
      <FormCard id={'mPodZM'} userId={shapeId} responses={responses['mPodZM']}/>
      <FormCard id={'ypGdvk'} userId={shapeId} responses={responses['ypGdvk']}/>
      <FormCard id={'Gr65UE'} userId={shapeId} responses={responses['Gr65UE']}/>
    </div>
  )
}
